import React from "react";
import UseTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { LanguageUid } from "../../libs/enums";
import Image from "next/image";
import Logo from "../../assets/images/footer-logo.png";
import styles from "./styles.module.css";

const CustomError = ({ statusCode }) => {
  const { t } = UseTranslation("GetLanguageResourceResponse");

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logoNotFound}>
          <Image src={Logo} alt="Logo" />
        </div>
        <span>{t(LanguageUid.TITLE)}</span>

        <div className={styles.section}>
          {/* Start Not Found Title */}
          <div className={styles.title}>
            {statusCode === 404
              ? t(LanguageUid.NOTFOUND)
              : t(LanguageUid.INTERNAL_SERVER)}
            {}
          </div>
          {/* End Not Found Title */}

          <hr className={styles.notfoundLine} />

          {/* Start Social Media */}
          <div className={styles.socialContent}>
            <span className={styles.socialContentTitle}>
              {t(LanguageUid.NOTFOUND_SOCIAL_TITLE)}
            </span>
            <ul className={styles.socialMedia}>
              <li>
                <Link
                  href={"https://www.facebook.com/Ptt.Kurumsal"}
                  rel="noreferer">
                  <a target="_blank" title="Facebook" aria-label="Facebook">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </Link>
              </li>
              <li>
                <Link
                  href={"https://www.instagram.com/pttkurumsal/"}
                  rel="noreferer">
                  <a target="_blank" title="Instagram" aria-label="Instagram">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </Link>
              </li>
              <li>
                <Link href={"https://twitter.com/PTTKurumsal"} rel="noreferer">
                  <a target="_blank" title="Twitter" aria-label="Twitter">
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          {/* End Social Media */}
        </div>
      </div>
    </>
  );
};

export default CustomError;
