import styles from "./styles.module.css";
import Image from "next/image";
import Bird from "../../assets/images/bird.png";
import { IsNullOrEmpty } from "../../libs/utils";

function Progress() {
  return (
    <div className={styles.progress}>
      {!IsNullOrEmpty(Bird) && (
        <Image
          src={Bird ?? ""}
          width={200}
          height={120}
          alt="Kuş görseli"
          title="Kuş görseli"
          role="img"
          aria-live="polite"
        />
      )}
    </div>
  );
}

export default Progress;
