import React from "react";
import Link from "next/link";
import styles from "./styles.module.css";
import {
  LanguageUid,
  LanguageValueType,
  MenuSettings,
  Language,
} from "../../../../libs/enums";
import UseTranslation from "next-translate/useTranslation";
import { CDN_URL } from "../../../../libs/generalSettings";
import { GetLanguageResource, IsNullOrEmpty } from "../../../../libs/utils";

const Special = ({ menus }) => {
  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  return (
    <nav className={styles.special} aria-label="Özel Menü Navigasyonu">
      <div className={styles._special} role="navigation">
        <ul className={styles.left} aria-label="Üst Menü">
          {menus &&
            menus
              .filter(
                (f) =>
                  f.slug == MenuSettings.SLUG.TOP_MENU &&
                  !IsNullOrEmpty(f.languageResources)
              )
              .map((specialMenu, index) => (
                <li key={index}>
                  <Link
                    href={`${CDN_URL}Ptt_Hizmet_Katalogu_(1)_compressed.pdf`}
                    rel="noreferer"
                    title="Hizmet Kataloğunu Aç"
                    aria-label="Hizmet Kataloğunu Aç">
                    <a target="_blank" title="Hizmet Kataloğunu Aç">
                      {
                        GetLanguageResource(
                          specialMenu.languageResources,
                          LanguageValueType.MENU
                        ).value
                      }
                    </a>
                  </Link>
                </li>
              ))}
        </ul>

        <ul className={styles.right} aria-label="İletişim Bilgileri">
          <li>
            <Link
              href={"tel:4441788"}
              title="Telefon ile İletişim"
              aria-label="Telefon ile İletişim">
              <a title="Telefon ile İletişim">
                <i className="fa-solid fa-phone" aria-hidden="true"></i>
                <span>444 1 788</span>
              </a>
            </Link>
          </li>
          {lang.toUpperCase() === Language.LanguageText.TR.toUpperCase() ? (
            <li>
              <Link
                href={"/iletisim-bilgileri"}
                title="Bize Ulaşın"
                aria-label="Bize Ulaşın">
                <a title="Bize Ulaşın">
                  <i className="fa-solid fa-paper-plane" aria-hidden="true"></i>
                  <span>{t(LanguageUid.CONTACT_US)}</span>
                </a>
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>

        <ul className={styles.social}>
          <li>
            <Link
              href={"https://www.facebook.com/Ptt.Kurumsal"}
              rel="noreferer"
              title="Facebook Sayfamız"
              aria-label="Facebook Sayfamız">
              <a target="_blank" title="Facebook Sayfamız">
                <i className="fa-brands fa-facebook-f" aria-hidden="true"></i>
              </a>
            </Link>
          </li>
          <li>
            <Link
              href={"https://www.instagram.com/pttkurumsal/"}
              rel="noreferer"
              title="Instagram Sayfamız"
              aria-label="Instagram Sayfamız">
              <a target="_span" title="Instagram Profilimiz">
                <i className="fa-brands fa-instagram" aria-hidden="true"></i>
              </a>
            </Link>
          </li>
          <li>
            <Link
              href={"https://x.com/PTTKurumsal"}
              rel="noreferer"
              title="X Sayfamız"
              aria-label="X Sayfamız">
              <a target="_blank" title="X Hesabımız">
                <i className="fa-brands fa-x-twitter" aria-hidden="true"></i>
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Special;
