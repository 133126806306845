import React, { useState, useEffect } from "react";
import Link from "next/link";
import { Post } from "../../../libs/api/rest";
import {
  LanguageUid,
  LanguageValueType,
  MenuSettings,
} from "../../../libs/enums";
import styles from "./styles.module.css";
import UseTranslation from "next-translate/useTranslation";
import { GetLanguageResource, IsNullOrEmpty } from "../../../libs/utils";
import Image from "next/image";
import FooterBgLogoLeft from "../../../assets/images/footer-bg-logo-left.png";
import FooterBgLogoRight from "../../../assets/images/footer-bg-logo-right.png";
import FooterLogo from "../../../assets/images/footer-logo.png";

const Footer = ({ menus }) => {
  const { t } = UseTranslation("GetLanguageResourceResponse");
  const [emailAddress, setEmailAddress] = useState({ value: "" });
  const [search, setSearch] = useState(false);
  const [spanText, setspanText] = useState("");
  const [showCookieBanner, setShowCookieBanner] = useState(true);

  const onSetMail = (e) => {
    setEmailAddress({ ...emailAddress, value: e.target.value });

    if (!IsNullOrEmpty(spanText)) {
      setspanText("");
    }
  };

  const GetUsefulMenu = () => {
    var getusefulMenuItems =
      menus &&
      menus
        .filter(
          (f) =>
            f.slug === MenuSettings.SLUG.USEFUL_LINKS &&
            !IsNullOrEmpty(f.languageResources)
        )
        .map((footerMenu) => (
          <li key={footerMenu.uid}>
            <Link
              href={
                footerMenu.url !== MenuSettings.PAGE_NO_ACTION
                  ? `${
                      !IsNullOrEmpty(
                        GetLanguageResource(
                          footerMenu.languageResources,
                          LanguageValueType.MENU
                        ).slug
                      )
                        ? GetLanguageResource(
                            footerMenu.languageResources,
                            LanguageValueType.MENU
                          ).slug
                        : footerMenu.url
                    }`
                  : "/#/"
              }
            >
              <a
                rel="noopener noreferrer"
                target={IsNullOrEmpty(footerMenu.url) ? "_parent" : "_blank"}
                tabIndex="0"
              >
                {
                  GetLanguageResource(
                    footerMenu.languageResources,
                    LanguageValueType.MENU
                  ).value
                }
              </a>
            </Link>
          </li>
        ));

    return getusefulMenuItems;
  };

  const GetOnlineTransactionMenu = () => {
    var getOnlineTransactionMenuItems =
      menus &&
      menus
        .filter(
          (f) =>
            f.slug === MenuSettings.SLUG.ONLINE_TRANSACTIONS &&
            !IsNullOrEmpty(f.languageResources)
        )
        .map((onlineTransaction) => (
          <li key={onlineTransaction.uid}>
            <Link
              href={
                onlineTransaction.url ||
                GetLanguageResource(
                  onlineTransaction.languageResources,
                  LanguageValueType.MENU
                ).slug
              }
            >
              <a rel="noopener noreferrer" target="_blank" tabIndex="0">
                {
                  GetLanguageResource(
                    onlineTransaction.languageResources,
                    LanguageValueType.MENU
                  ).value
                }
              </a>
            </Link>
          </li>
        ));

    return getOnlineTransactionMenuItems;
  };

  const onAddSubscriber = async () => {
    setSearch(true);

    if (IsNullOrEmpty(emailAddress.value)) {
      setSearch(false);
      setspanText(t(LanguageUid.FOOTER_SUBSCRIBER_ADD_CORRECT_MAIL_MESSAGE));
      return;
    }

    if (!/.+@.+\.[A-Za-z]+$/.test(emailAddress.value)) {
      setSearch(false);
      setspanText(t(LanguageUid.FOOTER_SUBSCRIBER_ADD_CORRECT_MAIL_MESSAGE));
      return;
    }

    if (emailAddress.value.split(" ").length - 1 > 0) {
      setSearch(false);
      setspanText(t(LanguageUid.FOOTER_SUBSCRIBER_ADD_CORRECT_MAIL_MESSAGE));
      return;
    }

    let subs = { Subscribers: [{ EmailAddress: "" }] };
    subs.Subscribers[0].EmailAddress = emailAddress.value;

    const isSameRequest = await fetch(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/Subscriber/getByMail?Mail=${emailAddress.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (isSameRequest.status === 200) {
      const sameResponse = await isSameRequest.json();
      if (!IsNullOrEmpty(sameResponse.emailAddress)) {
        setSearch(false);
        setspanText(t(LanguageUid.FOOTER_SUBSCRIBER_ADD_SAME_MAIL_MESSAGE));
        return;
      }
    }

    const request = await fetch(
      `${process.env.NEXT_PUBLIC_MAINAPI_URL}/Subscriber/add`.replace(
        /[^\x20-\x7E]/g,
        ""
      ),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subs),
      }
    );
    const result = await request.json();

    if (result.status === "Success") {
      setSearch(false);
      setEmailAddress({ value: "" });
      setspanText(t(LanguageUid.FOOTER_SUBSCRIBER_ADD_SUCCESS));
    }
  };

  return (
    <footer
      className={styles.footer}
      role="contentinfo"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Site Footer
      </h2>

      <div className="row">
        <div className="col-4 col-t-3 col-tv-12">
          <div className={styles.about}>
            <div className={styles.logoLeft}>
              <Image
                src={FooterBgLogoLeft}
                alt={t(LanguageUid.TITLE)}
                title={t(LanguageUid.TITLE)}
                role="img"
              />
            </div>

            <div className={styles.logoRight}>
              <Image
                src={FooterBgLogoRight}
                alt={t(LanguageUid.TITLE)}
                title={t(LanguageUid.TITLE)}
                role="img"
              />
            </div>

            <div className={styles.description}>
              {!IsNullOrEmpty(FooterLogo) && (
                <Image
                  src={FooterLogo}
                  alt={t(LanguageUid.TITLE)}
                  title={t(LanguageUid.TITLE)}
                  width={150}
                  height={50}
                  role="img"
                />
              )}

              <h1>{t(LanguageUid.FOOTER_ABOUT_TITLE)}</h1>
              <p>{t(LanguageUid.FOOTER_MISSION)}</p>
              <p>{t(LanguageUid.FOOTER_VISION)}</p>

              <ul className={styles.information} role="list">
                <li>
                  <i
                    className="fa-solid fa-location-dot"
                    aria-hidden="true"
                  ></i>
                  <address aria-label="Adres">
                    Şehit Teğmen Kalmaz Cad. No: 2 06050
                    <br />
                    <span>Ulus / ANKARA</span>
                  </address>
                </li>
                <li>
                  <i className="fa-solid fa-phone" aria-hidden="true"></i>
                  <Link href={"tel:4441788"}>
                    <a aria-label="Telefon numarası: 444 1 788">444 1 788</a>
                  </Link>
                </li>
                <li>
                  <i className="fa-solid fa-fax" aria-hidden="true"></i>
                  <Link href={"tel:03123095144"}>
                    <a aria-label="Faks numarası: (+90) 312 309 51 44">
                      (+90) 312 309 51 44
                    </a>
                  </Link>
                </li>
              </ul>

              <ul
                className={styles.socialMedia}
                role="list"
                aria-label="Sosyal Medya Bağlantıları"
              >
                <li role="listitem">
                  <Link
                    href={"https://www.facebook.com/Ptt.Kurumsal"}
                    rel="noreferer"
                  >
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Facebook"
                      aria-label="Facebook"
                      tabIndex="0"
                    >
                      <i
                        className="fa-brands fa-facebook-f"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </Link>
                </li>
                <li role="listitem">
                  <Link
                    href={"https://www.instagram.com/pttkurumsal/"}
                    rel="noreferer"
                  >
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Instagram"
                      aria-label="Instagram"
                      tabIndex="0"
                    >
                      <i
                        className="fa-brands fa-instagram"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </Link>
                </li>
                <li role="listitem">
                  <Link href={"https://x.com/PTTKurumsal"} rel="noreferer">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      title="X Hesabımız"
                      aria-label="X Hesabımız"
                      tabIndex="0"
                    >
                      <i
                        className="fa-brands fa-x-twitter"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-8 col-t-8 col-tv-12">
          <div className="row">
            {/* Start Useful Links */}
            <div
              className="col-3 col-t-4 col-tv-6"
              role="navigation"
              aria-label="Faydalı Bağlantılar"
            >
              <div className={styles.usefulLinks}>
                <h1>{t(LanguageUid.FOOTER_USEFUL_LINKS_TITLE)}</h1>
                <ul>{GetUsefulMenu()}</ul>
              </div>
            </div>
            {/* End Useful Links */}

            {/* Start Online Transactions */}
            <div
              className="col-4 col-t-4 col-tv-6"
              role="navigation"
              aria-label="Online İşlemler"
            >
              <div className={styles.onlineTransactions}>
                <h1>{t(LanguageUid.ONLINE_TRANSACTIONS)}</h1>
                <ul>{GetOnlineTransactionMenu()}</ul>
              </div>
            </div>
            {/* End Online Transactions */}

            {/* Start Subscription */}
            <div className="col-5 col-t-4 col-tv-12">
              <div className={styles.subscription}>
                <h1>{t(LanguageUid.FOOTER_NEWS_AND_ANNOUNCEMENT_TITLE)}</h1>
                <p>{t(LanguageUid.FOOTER_NEWS_AND_ANNOUNCEMENT_DESC)}</p>
                <div className={styles.input}>
                  <span className={styles.spanMessage}>{spanText}</span>
                  <label htmlFor="emailInput" className="sr-only">
                    {t(LanguageUid.FOOTER_INPUT_PLACEHOLDER)}
                  </label>
                  <input
                    type={"text"}
                    value={emailAddress.value}
                    maxLength="50"
                    aria-required="true"
                    onChange={(event) => {
                      onSetMail(event);
                    }}
                    placeholder={t(LanguageUid.FOOTER_INPUT_PLACEHOLDER)}
                  />
                  <button
                    onClick={async () => {
                      await onAddSubscriber();
                    }}
                    aria-label={t(LanguageUid.FOOTER_BUTTON_TEXT)}
                  >
                    {!search ? (
                      t(LanguageUid.FOOTER_BUTTON_TEXT)
                    ) : (
                      <i className="fa-solid fa-spinner" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* End Subscrition */}
          </div>
        </div>
      </div>
      {showCookieBanner && (
        <div className={styles.cookieBanner}>
          <p>
            Kullanıcı deneyiminizi iyileştirmek için verilerinizi yasal
            düzenlemelere uygun olarak kullanıyoruz. Detaylı bilgiye{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.ptt.gov.tr/gizlilik"
            >
              Gizlilik
            </a>{" "}
            sayfamızdan ulaşabilirsiniz.
          </p>
          <button
            className={styles.agreedButton}
            onClick={() => setShowCookieBanner(false)}
          >
            Anladım
          </button>
        </div>
      )}
    </footer>
  );
};

export default Footer;
