import React, { useState as UseState } from "react";
import Link from "next/link";
import styles from "./styles.module.css";
import { LanguageValueType, MenuSettings } from "../../libs/enums";

import { connect } from "react-redux";
import { Update_CustomServiceIsActive } from "../../redux/dispatch/getCustomServiceIsActive";
import { GetLanguageResource, IsNullOrEmpty } from "../../libs/utils";

const CustomServices = (props) => {
  const {
    customServiceMenus,
    getCustomServiceIsActive,
    onUpdateGetCustomServiceIsActive,
  } = props;

  const [_getCustomServiceIsActive, _setGetCustomServiceIsActive] = UseState();

  const onChangeVisible = async () => {
    await onUpdateGetCustomServiceIsActive(!getCustomServiceIsActive);
  };

  return (
    <section>
      <ul
        className={`${styles.customServicesMenu} ${
          !getCustomServiceIsActive ? styles.isVisibleCustomServicesMenu : null
        }`}
        aria-label="Servis Menüsü">
        <li
          onClick={() => {
            onChangeVisible();
          }}
          className={!getCustomServiceIsActive ? styles.wh : null}>
          <i
            className={`fa-solid fa-angle-${
              getCustomServiceIsActive ? "right" : "left"
            }`}
            aria-hidden="true"></i>
        </li>

        {customServiceMenus &&
          customServiceMenus
            .filter(
              (f) =>
                f.slug === MenuSettings.SLUG.RIGHT_MENU &&
                !IsNullOrEmpty(f.languageResources)
            )
            .map((item, index) => (
              <li
                key={index}
                className={!getCustomServiceIsActive ? styles.isVisible : null}>
                <Link href={item.url} rel="noreferrer">
                  <a aria-label={
                        GetLanguageResource(
                          item.languageResources,
                          LanguageValueType.MENU
                        ).value
                      } target={"_blank"}>
                    <i className={`${item.icon}`} aria-hidden="true"></i>
                    <span
                      >
                      {
                        GetLanguageResource(
                          item.languageResources,
                          LanguageValueType.MENU
                        ).value
                      }
                    </span>
                  </a>
                </Link>
              </li>
            ))}
      </ul>
    </section>
  );
};

const mapStateToProps = (state) => ({
  getCustomServiceIsActive: state.getCustomServiceIsActive,
});

const mapDispatchToProps = {
  onUpdateGetCustomServiceIsActive: Update_CustomServiceIsActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomServices);
