import {
  LanguageUid,
  MenuSettings,
  LanguageValueType,
} from "../../../../libs/enums";
import {
  GetLanguageResource,
  IsNullOrEmpty,
  OpenModelById,
} from "../../../../libs/utils";
import styles from "./styles.module.css";
import UseTranslation from "next-translate/useTranslation";
import Link from "next/link";

const Transactions = ({ menus }) => {
  const { t } = UseTranslation("GetLanguageResourceResponse");

  return (
    <div id="transactions" className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h1>{t(LanguageUid.ONLINE_TRANSACTIONS)}</h1>
          <div className="close" aria-label="Kapat">
            <i
              className="fa-solid fa-xmark"
              onClick={() => {
                OpenModelById("transactions");
              }}></i>
          </div>
        </div>

        <div className="modal-body">
          <ul className={styles.list}>
            {menus &&
              menus
                .filter(
                  (f) =>
                    f.slug == MenuSettings.SLUG.ONLINE_TRANSACTIONS &&
                    !IsNullOrEmpty(f.languageResources)
                )
                .map((transactionMenu) => (
                  <li key={transactionMenu.uid}>
                    <Link
                      href={
                        transactionMenu.url ||
                        GetLanguageResource(
                          transactionMenu.languageResources,
                          LanguageValueType.MENU
                        ).slug
                      }
                      rel="noreferrer">
                      <a
                        target={"_blank"}
                        aria-label={
                          GetLanguageResource(
                            transactionMenu.languageResources,
                            LanguageValueType.MENU
                          ).value
                        }>
                        <i className={transactionMenu.icon}></i>
                        <span>
                          {`${
                            GetLanguageResource(
                              transactionMenu.languageResources,
                              LanguageValueType.MENU
                            ).value
                          }`}
                        </span>
                      </a>
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
