import Link from "next/link";
import { OpenModelById, GetLanguageResource } from "../../../../libs/utils";
import styles from "./styles.module.css";
import {
  LanguageUid,
  LanguageValueType,
  MenuSettings,
  Language,
} from "../../../../libs/enums";
import UseTranslation from "next-translate/useTranslation";
import i18nConfig from "../../../../i18n.json";
import { useRouter as UseRouter } from "next/router";
import { useState as UseState } from "react";
import Image from "next/image";
import Logo from "../../../../assets/images/184logo.png";
import Englogo from "../../../../assets/images/englogo.png";
import { IsNullOrEmpty } from "../../../../libs/utils";
import { connect } from "react-redux";
import { Update_SearchModalIsActive } from "../../../../redux/dispatch/searchModal";
import SearchModal from "../../../search";
import yuzyil from "../../../../assets/images/yuzyil.png";

const Menu = ({ menus, searchModal, onUpdateSearchModalIsActive }) => {
  const { locales, defaultLocale } = i18nConfig;
  const { t, lang } = UseTranslation("GetLanguageResourceResponse");
  const router = UseRouter();

  const [mMenuOpen, setMMenuOpen] = UseState(false);
  const [screenWidth, setScreenWidth] = UseState(0);

  const onLinkControls = (url) => {
    switch (url) {
      case MenuSettings.PAGE_NO_ACTION:
        return "#/";
      default:
        return url;
    }
  };

  const onSubMenu = (menuId) => {
    return (
      <>
        {!IsNullOrEmpty(menus.filter((f) => f.parentMenuId === menuId)) && (
          <ul aria-label="Alt Menü">
            {menus
              .filter(
                (f) =>
                  f.parentMenuId === menuId &&
                  !IsNullOrEmpty(f.languageResources)
              )
              .map((menuSubItem, index) => (
                <li key={index}>
                  {menuSubItem.url === MenuSettings.SUB_HOME_PAGE ? (
                    <Link
                      href={`${
                        IsNullOrEmpty(menuSubItem.url) ||
                        menuSubItem.url === MenuSettings.SUB_HOME_PAGE
                          ? `/${
                              GetLanguageResource(
                                menuSubItem.languageResources,
                                LanguageValueType.MENU
                              ).slug
                            }`
                          : `${menuSubItem.url}`
                      }`}
                    >
                      <a
                        onClick={() => {
                          menuSubItem.url !== MenuSettings.SUB_HOME_PAGE &&
                            setMMenuOpen(!mMenuOpen);
                        }}
                        aria-label={`Alt Menü: ${
                          GetLanguageResource(
                            menuSubItem.languageResources,
                            LanguageValueType.MENU
                          ).value
                        }`}
                      >
                        <span>
                          {`${
                            GetLanguageResource(
                              menuSubItem.languageResources,
                              LanguageValueType.MENU
                            ).value
                          }`}
                        </span>

                        {menuSubItem.url === MenuSettings.SUB_HOME_PAGE ? (
                          screenWidth <= 790 ? (
                            <i className="fa-solid fa-plus"></i>
                          ) : (
                            <i className="fa-solid fa-angle-right"></i>
                          )
                        ) : null}
                      </a>
                    </Link>
                  ) : (
                    <Link
                      href={
                        menuSubItem.url !== MenuSettings.PAGE_NO_ACTION
                          ? menuSubItem.url === MenuSettings.PAGE
                            ? `/${
                                GetLanguageResource(
                                  menuSubItem.languageResources,
                                  LanguageValueType.MENU
                                ).slug
                              }`
                            : `${menuSubItem.url}`
                          : "/#/"
                      }
                    >
                      <a
                        onClick={() => {
                          menuSubItem.url !== MenuSettings.PAGE_NO_ACTION &&
                            setMMenuOpen(!mMenuOpen);
                        }}
                        aria-label={`Alt Menü: ${
                          GetLanguageResource(
                            menuSubItem.languageResources,
                            LanguageValueType.MENU
                          ).value
                        }`}
                      >
                        <span>
                          {`${
                            GetLanguageResource(
                              menuSubItem.languageResources,
                              LanguageValueType.MENU
                            ).value
                          }`}
                        </span>

                        {menuSubItem.url === MenuSettings.PAGE_NO_ACTION ? (
                          screenWidth <= 790 ? (
                            <i className="fa-solid fa-plus"></i>
                          ) : (
                            <i className="fa-solid fa-angle-right"></i>
                          )
                        ) : null}
                      </a>
                    </Link>
                  )}

                  {onSubMenu(menuSubItem.menuId)}
                </li>
              ))}
          </ul>
        )}
      </>
    );
  };

  const onSubMegaMenu = (menuId) => {
    return (
      <ul aria-label="Alt menü">
        {menus
          .filter(
            (f) =>
              f.parentMenuId === menuId && !IsNullOrEmpty(f.languageResources)
          )
          .map((megaMenuSubItem, index) => (
            <li key={index}>
              {megaMenuSubItem.url !== MenuSettings.PAGE_NO_ACTION ? (
                <Link
                  href={`${
                    IsNullOrEmpty(megaMenuSubItem.url) ||
                    megaMenuSubItem.url === MenuSettings.SUB_HOME_PAGE
                      ? `/${
                          GetLanguageResource(
                            megaMenuSubItem.languageResources,
                            LanguageValueType.MENU
                          ).slug
                        }`
                      : `${megaMenuSubItem.url}`
                  }`}
                >
                  <a
                    onClick={() => {
                      megaMenuSubItem.url !== MenuSettings.PAGE_NO_ACTION &&
                        setMMenuOpen(!mMenuOpen);
                    }}
                    aria-label={`$${
                      GetLanguageResource(
                        megaMenuSubItem.languageResources,
                        LanguageValueType.MENU
                      ).value
                    } bağlantısı`}
                  >
                    {
                      GetLanguageResource(
                        megaMenuSubItem.languageResources,
                        LanguageValueType.MENU
                      ).value
                    }

                    {megaMenuSubItem.url === MenuSettings.SUB_HOME_PAGE && (
                      <i className="fa-solid fa-plus"></i>
                    )}
                  </a>
                </Link>
              ) : (
                <span
                  aria-label={`$${
                    GetLanguageResource(
                      megaMenuSubItem.languageResources,
                      LanguageValueType.MENU
                    ).value
                  } metni`}
                >
                  {
                    GetLanguageResource(
                      megaMenuSubItem.languageResources,
                      LanguageValueType.MENU
                    ).value
                  }

                  {megaMenuSubItem.url === MenuSettings.PAGE_NO_ACTION && (
                    <i id="hasList" className="fa-solid fa-plus"></i>
                  )}
                </span>
              )}

              {onSubMegaMenu(megaMenuSubItem.menuId)}
            </li>
          ))}
      </ul>
    );
  };

  const getMenuItems = () => {
    const getMenuItems =
      menus &&
      menus
        .filter(
          (f) =>
            f.slug === MenuSettings.SLUG.MENU &&
            f.parentMenuId === MenuSettings.PARENT_MENU_ID.MENU &&
            !IsNullOrEmpty(f.languageResources) &&
            (router.pathname === "/" ? f.menuId != "107" : true)
        )
        .map((menu, index) => (
          <li
            key={index}
            role="listitem"
            aria-haspopup={menu.isMenuOpen ? "true" : "false"}
            aria-expanded={menu.isMenuOpen ? "true" : "false"}
            onClick={
              menu.isMenuOpen
                ? () => {
                    OpenModelById("megaMenu");
                  }
                : null
            }
          >
            {!menu.isMenuOpen ? (
              <Link
                href={
                  menu.url === MenuSettings.PAGE
                    ? `${
                        IsNullOrEmpty(menu.url)
                          ? `/${
                              GetLanguageResource(
                                menu.languageResources,
                                LanguageValueType.MENU
                              ).slug
                            }`
                          : menu.url
                      }`
                    : onLinkControls(menu.url)
                }
              >
                <a
                  onClick={() => {
                    menu.url !== MenuSettings.PAGE_NO_ACTION &&
                      setMMenuOpen(!mMenuOpen);
                  }}
                  aria-label={
                    GetLanguageResource(
                      menu.languageResources,
                      LanguageValueType.MENU
                    ).value
                  }
                >
                  <span>
                    {`${
                      GetLanguageResource(
                        menu.languageResources,
                        LanguageValueType.MENU
                      ).value
                    }`}
                  </span>
                </a>
              </Link>
            ) : (
              <span>{`${
                GetLanguageResource(
                  menu.languageResources,
                  LanguageValueType.MENU
                ).value
              }`}</span>
            )}

            {onSubMenu(menu.menuId)}
          </li>
        ));

    return <ul className={styles.list}>{getMenuItems}</ul>;
  };

  const getMegaMenuItems = () => {
    const getMegaMenuItems =
      menus &&
      menus
        .filter(
          (f) =>
            f.slug === MenuSettings.SLUG.MEGA_MENU &&
            f.parentMenuId === 0 &&
            !IsNullOrEmpty(f.languageResources)
        )
        .map((megaMenuSubItem) => (
          <li key={megaMenuSubItem.uid}>
            <h1>
              <i className={megaMenuSubItem.icon} aria-hidden="true"></i>
              <label>
                {`${
                  GetLanguageResource(
                    megaMenuSubItem.languageResources,
                    LanguageValueType.MENU
                  ).value
                }`}
              </label>
            </h1>

            {onSubMegaMenu(megaMenuSubItem.menuId)}
          </li>
        ));

    return <ul className={styles.megaMenuList}>{getMegaMenuItems}</ul>;
  };

  const onChangeLanguage = () => {
    locales.map((m) => {
      if (m === lang) return null;

      const langType = m === defaultLocale ? "" : m;
      router.push(`/${langType}`, null, { locale: m });
    });
  };

  return (
    <>
      {/* Start Mobile OnlineTransaction And InternetBanking */}
      <div className={styles.mOnlineTransactionAndInternetBanking}>
        <span>
          <Link
            href={"https://pttbank.ptt.gov.tr/pttwebapproot/ipcservlet"}
            rel="noreferrer"
          >
            <a target={"_blank"} aria-label={t(LanguageUid.INTERNET_BANKING)}>
              {t(LanguageUid.INTERNET_BANKING)}
            </a>
          </Link>
        </span>
        <span
          aria-label={t(LanguageUid.ONLINE_TRANSACTIONS)}
          onClick={() => {
            OpenModelById("transactions");
          }}
        >
          {t(LanguageUid.ONLINE_TRANSACTIONS)}
        </span>
      </div>
      {/* End Mobile OnlineTransaction And InternetBanking */}

      <nav className={styles.header} role="navigation" aria-label="Üst menü">
        {/* Start Tablet and Mobil Menu Icon */}
        <div className={styles.mMenu}>{mMenuOpen && getMenuItems()}</div>
        {/* End Tablet and Mobil Menu Icon */}

        {/* Start Logo */}

        <div className={styles.logos}>
          <Link href="/" aria-label={t(LanguageUid.LOGO_TITLE)}>
            <a className={styles.logo}>
              {!IsNullOrEmpty(Logo) &&
                (router.locale === Language.LanguageText.TR ? (
                  <Image
                    src={Logo}
                    alt={t(LanguageUid.LOGO_TITLE)}
                    title={t(LanguageUid.LOGO_TITLE)}
                    width={200}
                    height={80}
                  />
                ) : (
                  <Image
                    src={Englogo}
                    alt={t(LanguageUid.LOGO_TITLE)}
                    title={t(LanguageUid.LOGO_TITLE)}
                    width={135}
                    height={65}
                  />
                ))}
            </a>
          </Link>
        </div>

        {/* End Logo */}

        {/* Start List */}
        {getMenuItems()}
        {/* End List */}

        {/* Start Special */}
        <ul className={styles.special}>
          <li>
            <span>
              <Link
                href={"https://pttbank.ptt.gov.tr/pttwebapproot/ipcservlet"}
                rel="noreferrer"
              >
                <a
                  aria-label={t(LanguageUid.INTERNET_BANKING)}
                  target={"_blank"}
                >
                  {t(LanguageUid.INTERNET_BANKING)}
                </a>
              </Link>
            </span>
            <span
              aria-label={t(LanguageUid.ONLINE_TRANSACTIONS)}
              onClick={() => {
                OpenModelById("transactions");
              }}
            >
              {t(LanguageUid.ONLINE_TRANSACTIONS)}
            </span>
          </li>

          <li
            onClick={() => {
              onChangeLanguage();
            }}
            className={styles.lang}
          >
            <span>
              {locales.map((m) => {
                if (m !== lang) return m.toLocaleUpperCase();
              })}
            </span>
          </li>

          <li
            className={styles.search}
            aria-label="Arama yap"
            onClick={() => {
              onUpdateSearchModalIsActive(!searchModal);
            }}
          >
            <i className="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
          </li>

          <li>
            <div
              role="button"
              onClick={() => {
                setMMenuOpen(!mMenuOpen);
              }}
              className={styles.icon}
              aria-label="Menü Aç / Kapat"
              aria-expanded={mMenuOpen}
            >
              <i
                className={
                  !mMenuOpen ? "fa-solid fa-bars" : "fa-solid fa-xmark"
                }
              ></i>
            </div>
          </li>
        </ul>
        {/* End Special */}
      </nav>

      {/* Start Mega Menu */}
      <div
        id="megaMenu"
        className={styles.menu}
        role="Diyalog"
        aria-labelledby="Büyük Menü"
      >
        <h1>{t(LanguageUid.SERVICES)}</h1>
        <div
          onClick={() => {
            OpenModelById("megaMenu");
          }}
          className={styles.closeButton}
          aria-label="Büyük Menüyü Kapat"
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        {getMegaMenuItems()}
      </div>
      {/* End Mega Menu */}

      {searchModal && <SearchModal />}
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  onUpdateSearchModalIsActive: Update_SearchModalIsActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
